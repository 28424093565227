import React, { useState } from "react";
import firebase from "gatsby-plugin-firebase";
import { Button, Modal } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import "./custom-header-bg.css"

const Contact = () => {
  const emptyData = {
    Organization: "",
    Website: "",
    Address: "",
    Street: "",
    City: "",
    Country: "",
    OrgIsd: "",
    OrgPhone: "",
    YourName: "",
    Designation: "",
    Email: "",
    YourIsd: "",
    YourPhone: "",
    Subject: "",
    Message: "",
  }
  const [data, setData] = useState(emptyData)
  const [show, setModalShow] = useState(false);

  const handleModalClose = () => setModalShow(false);
  const handleModalShow = () => setModalShow(true);

  const handleChange = e =>
    setData({ ...data, [e.target.name]: e.target.value })

  const handleSubmit = (e) => {
    //console.log('handleSubmit')
    if (typeof window !== `undefined`) {
      e.preventDefault();
      let myForm = document.getElementById("customer-contact");
      let formData = new FormData(myForm);
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString(),
      })
        .then(() => {
          console.log("Form successfully submitted to netlify");
          //console.log(data);
          data["created"] = firebase.firestore.Timestamp.now();
          data["status"] = "applied";
          firebase
            .firestore()
            .collection('master-contacts')
            .add({ email: data["Email"], type: "customer-contact", created: data["created"] })
            .then(() => {
              console.log("master-contacts: Form successfully saved in firestore")
            })
            .catch((error) => {
              console.error("Error adding document to master-contacts: ", error);
            });
          firebase
            .firestore()
            .collection('customer-contacts')
            .add(data)
            .then(() => {
              console.log("customer-contacts: Form successfully saved in firestore")
            })
            .catch((error) => {
              console.error("Error adding document to customer-contacts: ", error);
            });
          setData(emptyData);
          setModalShow(true);
        })
        .catch((error) => alert(error));
    }
  };

  return (
    <>
      <PageWrapper>
        <div className="custom-header-background pt-16 pb-12 pt-lg-22 pb-lg-27">
          <div className="container">
            <div className="row justify-content-center mt-14">
              <h2 className="font-size-9 text-center mb-11 text-white">Customer Registration Form</h2>
              <div className="mb-8">
                <p className="text-default-color font-size-4 px-5 text-white">
                  Drive your innovation and ideas to successful business outcomes faster – Come collaborate with us! We are your trusted technology partner. Brainstorm with leading CTOs on best technology directions and options, consult on high level design, execution, and implementation. Utilize our global network of domain experts, proven product and system design experts and engineers. We are Your extended R&D and CTO team – VOCALGLOCAL.org
                </p>
                <p className="text-default-color font-size-4 px-5 text-white">Reach out to us and share your idea. Get our expert feedback and engage with us.</p>
              </div>
              <div className="col-xxl-6 col-xl-7 col-lg-8">

                <div className="bg-white px-9 pt-9 pb-7 shadow-8 rounded-4">
                  <form
                    id="customer-contact"
                    name="customer-contact"
                    method="post"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    onSubmit={handleSubmit}
                  >
                    <input type="hidden" name="bot-field" />
                    <input type="hidden" name="form-name" value="customer-contact" />
                    <div className="row">
                      <div className="col-lg-12 mb-7">
                        <label
                          htmlFor="Organization"
                          className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                        >
                          Organization
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Organization Name"
                          id="Organization"
                          name="Organization"
                          value={data.Organization}
                          onChange={handleChange}
                          required
                        />
                      </div>

                      <div className="col-6 mb-7">
                        <label
                          htmlFor="Website"
                          className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                        >
                          Organization Website
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Organization Website"
                          id="Website"
                          name="Website"
                          value={data.Website}
                          onChange={handleChange}
                          required
                        />
                      </div>

                      <div className="col-lg-6 mb-7">
                        <label
                          htmlFor="Address"
                          className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                        >
                          Address
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Address"
                          id="Address"
                          name="Address"
                          value={data.Address}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="col-lg-6 mb-7">
                        <label
                          htmlFor="Street"
                          className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                        >
                          Street
                        </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Street"
                            id="Street"
                            name="Street"
                            value={data.Street}
                            onChange={handleChange}
                            required
                          />
                      </div>

                      <div className="col-lg-6 mb-7">
                        <label
                          htmlFor="City"
                          className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                        >
                          City
                        </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="City"
                            id="City"
                            name="City"
                            value={data.City}
                            onChange={handleChange}
                            required
                          />
                      </div>
                      <div className="col-lg-6 mb-7">
                        <label
                          htmlFor="Country"
                          className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                        >
                          Country
                        </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Country"
                            id="Country"
                            name="Country"
                            value={data.Country}
                            onChange={handleChange}
                            required
                          />
                      </div>

                      <div className="col-lg-6 mb-7">
                        <label
                          htmlFor="OrgPhone"
                          className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                        >
                          Organization Phone
                        </label>
                          <br />
                          <input
                            type="text"
                            className="form-control"
                            placeholder="###"
                            id="OrgIsd"
                            name="OrgIsd"
                            style={{ float: "left", width: "25%" }}
                            value={data.OrgIsd}
                            onChange={handleChange}
                            required
                          />
                          <span style={{ float: "left", width: "5%", textAlign: "center", paddingTop: "0.75rem" }}> - </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Phone Number"
                            id="OrgPhone"
                            name="OrgPhone"
                            style={{ float: "left", width: "70%" }}
                            value={data.OrgPhone}
                            onChange={handleChange}
                            required
                          />
                      </div>


                      <div className="col-lg-6 mb-7">
                        <label
                          htmlFor="YourName"
                          className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                        >
                          Your Name
                        </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Your Name"
                            id="YourName"
                            name="YourName"
                            value={data.YourName}
                            onChange={handleChange}
                            required
                          />
                      </div>
                      <div className="col-lg-6 mb-7">
                        <label
                          htmlFor="Designation"
                          className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                        >
                          Designation
                        </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Designation"
                            id="Designation"
                            name="Designation"
                            value={data.Designation}
                            onChange={handleChange}
                            required
                          />
                      </div>

                      <div className=" col-xs-12 col-lg-6 mb-7">
                        <label
                          htmlFor="Email"
                          className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                        >
                          E-mail
                        </label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="example@gmail.com"
                            id="Email"
                            name="Email"
                            value={data.Email}
                            onChange={handleChange}
                            required
                          />
                      </div>
                      <div className="col-lg-6 mb-7">
                        <label
                          htmlFor="YourPhone"
                          className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                        >
                          Your Phone
                        </label>
                          <br />
                          <input
                            type="text"
                            className="form-control"
                            placeholder="###"
                            id="YourIsd"
                            name="YourIsd"
                            value={data.YourIsd}
                            onChange={handleChange}
                            style={{ float: "left", width: "25%" }}
                            required
                          />
                          <span style={{ float: "left", width: "5%", textAlign: "center", paddingTop: "0.75rem" }}> - </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Phone Number"
                            id="YourPhone"
                            name="YourPhone"
                            value={data.YourPhone}
                            onChange={handleChange}
                            style={{ float: "left", width: "70%" }}
                            required
                          />
                      </div>

                      <div className="col-lg-12 mb-7">
                        <label
                          htmlFor="Subject"
                          className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                          style={{ width: "100%" }}
                        >
                          Subject
                        </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Subject"
                            id="Subject"
                            name="Subject"
                            value={data.Subject}
                            onChange={handleChange}
                            required
                          />
                      </div>
                      <div className="col-lg-12 mb-7">
                        <label
                          htmlFor="Message"
                          className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                          style={{ width: "100%" }}
                        >
                          Message
                          <textarea
                            id="Message"
                            placeholder="Brief message on your requirements"
                            className="form-control mt-5 h-px-144"
                            name="Message"
                            value={data.Message}
                            onChange={handleChange}
                            required
                          ></textarea>
                        </label>
                      </div>
                      <div className="col-lg-12 pt-4">
                        <button
                          type="submit"
                          className="btn btn-orange text-uppercase w-100 h-px-48"
                        >
                          Send Now
                        </button>
                      </div>
                    </div>
                  </form>
                  <div className="mt-8">
                    <h3 className="font-size-4">Our Contact Details</h3>
                    <div className="media mb-2">
                      <div className="mr-6">
                        <i className="fas fa-envelope mt-2"></i>
                      </div>
                      <p className="font-size-4 mb-0">info@VOCALGLOCAL.org</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={show} onHide={handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Thank you!</Modal.Title>
          </Modal.Header>
          <Modal.Body>Your form submission has been received.</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleModalClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </PageWrapper>
    </>
  );
};
export default Contact;
